import React from "react"
import Layout from "../components/layout"

export default class Post extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const s = this.state

        return (
            <Layout>
                <div className="main-image">
                    <img src="../img.jpg" alt=""/>
                </div>
                <div className="content">
                    <div className="page-title">
                        <h3>INFORMACJE O ŚWIETLICY</h3>
                    </div>
                    <div className="page">
                        <h1>Headling H1</h1>
                        <h2>Headling H2</h2>
                        <h3>Headling H3</h3>
                        <h4>Headling H4</h4>
                        <h5>Headling H4</h5>
                        <h6>Headling H4</h6>
                        <p>1) Organizuje poradnictwo rodzinne dla rodziców lub prawnych opiekunów dziecka realizowane
                            przez psychologa, wychowawców. Konsultacje mają na celu tworzenie warunków sprzyjających
                            rozwojowi dziecka, rozwijanie umiejętności opiekuńczo- wychowawczych rodziny, wsparcie
                            rodziny w rozwiązywaniu problemów wychowawczych, rodzinnych oraz pomoc w sytuacjach
                            kryzysowych.</p>
                        <ul>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                        </ul>
                        <div className="page-title">
                            <h3>JAK ZAPISAĆ DZIECKO?</h3>
                        </div>
                        <div className="page-title">
                            <h3>KONTAKT</h3>
                        </div>
                    </div>
                </div>
                <div className="contact-section contact-section--fourth">
                    <div className="content">
                        <h3>Swietlica - Kowalska 3</h3>
                        <div className="contact-section__row">
                            <i className="icon-home"></i>
                            <p>ul. Kowalska 3<br />20-085 Lublin</p>
                        </div>
                        <div className="contact-section__row">
                            <i className="icon-call"></i>
                            <p>Telefon: 792 104 410</p>
                        </div>
                        <div className="contact-section__row">
                            <i className="icon-email"></i>
                            <p><a href="mailto:swietlica@sempre.org.pl">swietlica@sempre.org.pl</a></p>
                        </div>
                        <p><strong>Pracujemy w godzinach <span>14:00-18:00</span> od poniedziałku do piątku.</strong></p>
                    </div>
                </div>
            </Layout>
        )
    }
}
